<template>
  <div class="create-content-main">
    <div class="create-form">
      <div class="cpy-2">
        <span class="cpx-4 font-title">一般設定</span>
      </div>
      <form class="cpx-15 pb-2" id="create-content" autocomplete="off">
        <div class="row mb-3 row-input">
          <label class="col-sm-12 custom-text cp-label fontStyle" style=""
            >サイト名</label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="shopTitle"
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 col-lg-12 custom-text cp-label fontStyle"
            >ロゴ（推奨のサイズ 286 x 79 px）</label
          >
          <div class="col-sm-12 col-lg-12 mb-3 img-custome cp-4">
            <div class="w-100 mt-1 px-3">
              <label
                for="fileInput"
                class="btn label-input-file btn-color"
                style="color: white; border-radius: 3px"
                >画像選択</label
              >
              <CButton
                v-if="urlImageLogo"
                class="mx-1 mb-2"
                color="success"
                style="
                  background-color: #66615b;
                  border-radius: 3px;
                  border-color: #66615b;
                "
                square
                v-on:click="deleteImageLogo()"
              >
                画像削除
              </CButton>
              <div class="row">
                <div class="col-lg-3">
                  <input
                    id="fileInput"
                    class="d-none"
                    @change="onChangeimageLogo($event)"
                    type="file"
                  />
                  <div style="border: 1px solid #000" class="w-100 mt-lg-3">
                    <img
                      v-if="urlImageLogo"
                      :src="urlImageLogo"
                      width="100%"
                      height="150vw"
                      style="object-fit: contain"
                    />
                    <img
                      v-else-if="urlImageLogo == null || urlImageLogo == ''"
                      src="@/assets/img/no-image.jpg"
                      width="100%"
                      height="150vw"
                      style="object-fit: contain"
                    />
                  </div>
                  <p v-if="checkImg">
                    <img class="preview-img" :src="urlImageLogo" alt="" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 row-input">
          <label class="col-sm-12 col-lg-12 custom-text cp-label fontStyle"
            >ログイン画面背景（推奨のサイズ 1024 x 768 px 以上）</label
          >
          <div class="col-sm-12 col-lg-12 mb-3 img-custome cp-4">
            <div class="w-100 mt-1 px-3">
              <label
                for="fileInputLogin"
                class="btn label-input-file btn-color"
                style="color: white; border-radius: 3px"
                >画像選択</label
              >
              <CButton
                v-if="urlImageLogin"
                class="mx-1 mb-2"
                color="success"
                style="
                  background-color: #66615b;
                  border-radius: 3px;
                  border-color: #66615b;
                "
                square
                v-on:click="deleteImageLogin()"
              >
                画像削除
              </CButton>
              <div class="row">
                <div class="col-lg-3">
                  <input
                    id="fileInputLogin"
                    class="d-none"
                    @change="onChangeImageLogin($event)"
                    type="file"
                  />
                  <div style="border: 1px solid #000" class="w-100 mt-lg-3">
                    <img
                      v-if="urlImageLogin"
                      :src="urlImageLogin"
                      width="100%"
                      height="150vw"
                      style="object-fit: contain"
                    />
                    <img
                      v-else-if="urlImageLogin == null || urlImageLogin == ''"
                      src="@/assets/img/no-image.jpg"
                      width="100%"
                      height="150vw"
                      style="object-fit: contain"
                    />
                  </div>
                  <p v-if="checkImg">
                    <img class="preview-img" :src="avatar" alt="" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 row-input">
          <label class="col-sm-12 col-lg-12 custom-text cp-label fontStyle"
            >404ページに設定する画像</label
          >
          <div class="col-sm-12 col-lg-12 mb-3 img-custome cp-4">
            <div class="w-100 mt-1 px-3">
              <label
                for="fileInput404"
                class="btn label-input-file btn-color"
                style="color: white; border-radius: 3px"
                >画像選択</label
              >
              <CButton
                v-if="urlImage404"
                class="mx-1 mb-2"
                color="success"
                style="
                  background-color: #66615b;
                  border-radius: 3px;
                  border-color: #66615b;
                "
                square
                v-on:click="deleteImage404()"
              >
                画像削除
              </CButton>
              <div class="row">
                <div class="col-lg-3">
                  <input
                    id="fileInput404"
                    class="d-none"
                    @change="onChangeImage404($event)"
                    type="file"
                  />
                  <div style="border: 1px solid #000" class="w-100 mt-lg-3">
                    <img
                      class=""
                      v-if="urlImage404"
                      :src="urlImage404"
                      width="100%"
                      height="150vw"
                      style="object-fit: contain"
                    />
                    <img
                      v-else-if="urlImage404 == null || urlImage404 == ''"
                      src="@/assets/img/no-image.jpg"
                      width="100%"
                      height="150vw"
                      style="object-fit: contain"
                    />
                  </div>
                  <p v-if="checkImg">
                    <img class="preview-img" :src="avatar" alt="" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 row-input" v-if="user_type == typeValue['cdea']">
          <label class="col-sm-12 custom-text cp-label fontStyle" style=""
            >お問い合わせURL</label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="inquiry_URL"
            />
          </div>
        </div>
        <div class="row mb-3 row-input">
          <label class="col-sm-12 fontStyle custom-text cp-label" style=""
            >会員登録解除後リダイレクトURL</label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="canceling_membership_url"
            />
          </div>
        </div>
        <div class="row mb-3 row-input" v-if="user_type == typeValue['cdea']">
          <label class="col-sm-12 fontStyle custom-text cp-label"
            >全体の文字行間（px）</label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12 col-lg-3"
              type="number"
              v-model="line_spacing"
              :min="0"
              onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
            />
          </div>
        </div>
        <div class="row mb-3 row-input">
          <label class="col-sm-12 fontStyle custom-text cp-label"
            >使用するメルマガ同期HTML</label
          >
          <div class="cp-4 w-100">
            <multiselect
              class="col-sm-12 col-lg-6 p-0"
              v-model="email_newsletter"
              tag-placeholder=""
              placeholder=""
              label="text"
              track-by="value"
              :options="listMailMegas"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              :select-label="''"
              :deselect-label="''"
              :selected-label="''"
              style=""
              v-bind:class="{
                'error-input': errorMailMegaHiddenRequired,
              }"
            ></multiselect>
          </div>
          <!-- <b-form-select
            v-model="email_newsletter"
            tag-placeholder="Add this as new category"
            :options="optionEmailNewsletter"
            class="col-sm-12 col-lg-2"
          ></b-form-select> -->
        </div>
        <div class="row mb-3 row-input">
          <label class="col-sm-12 fontStyle custom-text cp-label"
            >会員の退会を有効化する</label
          >
          <div class="cp-4 w-100">
            <b-form-select
              v-model="enable_membership"
              tag-placeholder="Add this as new category"
              :options="optionenable_membership"
              class="col-sm-12 col-lg-6"
            ></b-form-select>
          </div>
        </div>
        <div class="row mb-3 row-input">
          <label class="col-sm-12 fontStyle custom-text cp-label"
            >著者のデフォルト</label
          >
          <div class="cp-4 w-100">
            <b-form-select
              v-model="author_default"
              tag-placeholder="Add this as new category"
              :options="listAuthorOptions"
              class="col-sm-12 col-lg-6"
            ></b-form-select>
          </div>
        </div>
        <!-- <div class="row mb-3 row-input">
          <label class="col-sm-12 pl-0 fontStyle">テーマ設定</label>
          <b-form-select
            v-model="theme_setting"
            tag-placeholder="Add this as new category"
            :options="optiontheme_setting"
            class="col-sm-12 col-lg-6"
          ></b-form-select>
        </div> -->
        <div class="row mb-3 row-input" v-if="user_type == typeValue['cdea']">
          <div class="col-sm-12 px-0">
            <label class="col-sm-12 fontStyle custom-text cp-label">
              追加CSS
            </label>
            <div class="cp-4 w-100">
              <vue-editor
                useCustomImageHandler
                @image-added="handleImageAdded"
                @image-removed="handleImageRemove"
                v-model="additional_css"
                class="w-100"
              ></vue-editor>
            </div>
          </div>
        </div>
        <div class="row mb-3 row-input">
          <div class="col-sm-12 px-0">
            <label class="col-sm-12 fontStyle custom-text cp-label">
              メンテナンス画面設定（有効にすると、ユーザー画面がメンテナンス画面になります。利用の際はご注意ください）
            </label>
            <div class="cp-4 w-100 d-flex align-items-center">
              <b-form-checkbox
                v-model="isMaintenance"
                class="mr-3"
                id="radio_1"
                name="public-radios"
                value="1"
                unchecked-value="0"
                >メンテナンス画面を有効にする。</b-form-checkbox
              >
              <div class="d-flex align-items-center w-50">
                <span style="width: 15%">表示画面：</span>
                <b-form-input v-model="urlMaintenance"></b-form-input>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row mb-3 row-input">
          <label class="col-sm-12 fontStyle custom-text cp-label"
            >販売元の名称</label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12 col-lg-3"
              type="text"
              v-model="distributor_name"
            />
          </div>
        </div> -->
        <div class="row mb-3 row-input">
          <div class="col-sm-12 px-0">
            <label class="col-sm-12 fontStyle custom-text cp-label">
              全ページコピー禁止機能
            </label>
            <div class="cp-4 w-100 d-flex align-items-center">
              <b-form-checkbox
                v-model="isContextMenu"
                class="mr-3"
                value="1"
                unchecked-value="0"
                >コピー禁止機能をONにする
              </b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center my-4">
          <b-button
            v-on:click.prevent="SettingGeneral()"
            :disabled="isLoading"
            class="mr-4 btn-color"
            variant="success"
          >
            <span v-show="!isLoading"> 保存 </span>
            <div
              v-show="isLoading"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <span class="sr-only">ローディング...</span>
            </div>
          </b-button>
          <b-button
            variant="secondary"
            class="mr-4 back-btn"
            v-on:click="returnList()"
            >戻る</b-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { VueEditor } from "vue2-editor";
import { Urls } from "../../utils/urls.js";
import { Api } from "../../utils/http-common.js";
import { Constants } from "../../utils/constants";
export default {
  name: "general_setting",
  components: {
    VueEditor,
  },
  data() {
    return {
      shopTitle: "",
      isLoading: false,
      isPreview: false,
      checkImg: false,
      hasImage: false,
      image_logo: "",
      background_login: "",
      image_404: "",
      urlImageLogo: null,
      urlImageLogin: null,
      imageLogo: null,
      imageBackgroundLogin: null,
      urlImage404: null,
      image404: null,
      privacy_policy: "",
      managementofsalessite: "",
      specified: "",
      redirect_URL: "",
      inquiry_URL: "",
      line_spacing: "",
      email_newsletter: [],
      optionEmailNewsletter: [],
      enable_membership: true,
      optionenable_membership: [
        { text: "有効化しない", value: 0 },
        { text: "有効化する", value: 1 },
      ],
      theme_setting: "",
      optiontheme_setting: [],
      specified_URL: "",
      additional_css: "",
      id: "",
      qa: "a",
      is_delete_logo: 0,
      is_delete_login: 0,
      is_delete_404: 0,
      listMailMegas: [],
      page: 2,
      canceling_membership_url: null,
      errorMailMegaHiddenRequired: false,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      listAuthorOptions: [],
      author_default: null,
      user_type: localStorage.getItem(
        Constants.USER_TYPE_ADMIN +
          (this.$route.params.shopId
            ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
            : "_" + Constants.DOMAIN)
      ),
      typeValue: Constants.USER_TYPE_DEFAULT,
      isMaintenance: 0,
      urlMaintenance: "",
      listPageOptions: [],
      // distributor_name: "",
      isContextMenu: 0,
    };
  },
  created() {
    const request = {
      shop_id: this.shop_id,
    };
    this.getListAuthorInShop(request);
    const requestContent = {
      data: {
        shop_id: this.shop_id,
      },
      page: 1,
    };
    this.getlistMailMega(requestContent);
    this.getListFixed(request);
    this.getGeneralSettingByShopId(this.shop_id);
    this.$store.commit("set", ["success", false]);
    this.$store.commit("set", ["message", ""]);
    this.$store.commit("set", ["error", false]);
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listMailMega() {
      document
        .getElementsByClassName("multiselect__content-wrapper")[0]
        .addEventListener("scroll", this.scrollMailMega);
      this.listMailMegas = this.listMailMega.data.map((item) => ({
        value: item.id,
        text: item.title,
      }));
    },
    generalsetting() {
      if (this.generalsetting.length > 0) {
        this.id = this.generalsetting[0].id;
        if (this.generalsetting[0].logo !== null) {
          this.urlImageLogo =
            Constants.URL_BE + "/" + this.generalsetting[0].logo;
        } else {
          this.urlImageLogo = null;
        }
        if (this.generalsetting[0].background !== null) {
          this.urlImageLogin =
            Constants.URL_BE + "/" + this.generalsetting[0].background;
        } else {
          this.urlImageLogin = null;
        }
        if (this.generalsetting[0].page_404 !== null) {
          this.urlImage404 =
            Constants.URL_BE + "/" + this.generalsetting[0].page_404;
        } else {
          this.urlImage404 = null;
        }
        this.privacy_policy = this.generalsetting[0].privacy_policy;
        this.managementofsalessite = this.generalsetting[0].terms;
        this.specified_URL = this.generalsetting[0].url_trade_law;
        this.redirect_URL = this.generalsetting[0].url_redirect;
        this.inquiry_URL = this.generalsetting[0].url_qa;
        this.line_spacing = this.generalsetting[0].px;
        this.enable_membership = this.generalsetting[0].delete_allow_account;
        this.theme_setting = this.generalsetting[0].setting_theme;
        this.additional_css = this.generalsetting[0].css
          ? this.generalsetting[0].css
          : "";
        this.shopTitle = this.generalsetting[0].shop_title
          ? this.generalsetting[0].shop_title
          : "";
        this.canceling_membership_url =
          this.generalsetting[0].url_delete_account;
        this.author_default = this.generalsetting[0].author_default;
        const listMega = this.generalsetting[0].form_html;
        if (listMega) {
          listMega.forEach((element) => {
            this.listMailMegas.forEach((item) => {
              if (item.value === parseInt(element)) {
                this.email_newsletter.push(item);
              }
            });
          });
        }
        this.isMaintenance = this.generalsetting[0].is_maintenance;
        this.isContextMenu = this.generalsetting[0].is_contextmenu;
        this.urlMaintenance = this.generalsetting[0].url_maintenance;
        // this.distributor_name = this.generalsetting[0].distributor_name;
      }
    },
    listAuthor() {
      if (this.listAuthor.length > 0) {
        this.listAuthorOptions = this.listAuthor.map((item) => ({
          text:
            item && item.name && item.name.length > Constants.MAX_LENGTH
              ? item.name.substr(0, Constants.MAX_LENGTH) + "..."
              : item.name,
          value: item.id.toString(),
        }));
      } else {
        this.listAuthorOptions = [
          { text: "リストは空です", value: null, disabled: true },
        ];
      }
    },
    listFixedPage() {
      this.listPageOptions = this.listFixedPage.map((item) => ({
        value: item.id,
        text: item.title,
      }));
    },
  },
  computed: {
    ...mapGetters([
      "generalsetting",
      "success",
      "message",
      "error",
      "listMailMega",
      "listAuthor",
      "listFixedPage",
    ]),
  },
  methods: {
    ...mapActions({
      GerenalSetting: "GerenalSetting",
      getGeneralSettingByShopId: "getGeneralSettingByShopId",
      getlistMailMega: "getlistMailMega",
      getListAuthorInShop: "getListAuthorInShop",
      getListFixed: "getListFixed",
    }),
    onChangeimageLogo(e) {
      if (
        e.target.files[0].type === "image/jpeg" ||
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpg"
      ) {
        this.imageLogo = e.target.files[0];
        this.urlImageLogo = URL.createObjectURL(this.imageLogo);
      } else {
        this.$toasted.error("画像がjpg、jpeg、png形式である必要があります。");
      }
    },
    onChangeImageLogin(e) {
      if (
        e.target.files[0].type === "image/jpeg" ||
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpg"
      ) {
        this.imageBackgroundLogin = e.target.files[0];
        this.urlImageLogin = URL.createObjectURL(this.imageBackgroundLogin);
      } else {
        this.$toasted.error("画像がjpg、jpeg、png形式である必要があります。");
      }
    },
    onChangeImage404(e) {
      if (
        e.target.files[0].type === "image/jpeg" ||
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpg"
      ) {
        this.image404 = e.target.files[0];
        this.urlImage404 = URL.createObjectURL(this.image404);
      } else {
        this.$toasted.error("画像がjpg、jpeg、png形式である必要があります。");
      }
    },
    deleteImageLogo() {
      const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
      const method = "POST";
      Api.adminRequestServer({
        method,
        url,
        data: this.urlImageLogo,
      });
      this.urlImageLogo = null;
      this.imageLogo = null;
      this.is_delete_logo = 1;
      document.getElementById("fileInput").value = "";
    },
    deleteImageLogin() {
      const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
      const method = "POST";
      Api.adminRequestServer({
        method,
        url,
        data: this.urlImageLogin,
      });
      this.urlImageLogin = null;
      this.is_delete_login = 1;
      this.imageBackgroundLogin = null;
      document.getElementById("fileInputLogin").value = "";
    },
    deleteImage404() {
      const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
      const method = "POST";
      Api.adminRequestServer({
        method,
        url,
        data: this.urlImage404,
      });
      this.urlImage404 = null;
      this.is_delete_404 = 1;
      this.image404 = null;
      document.getElementById("fileInput404").value = "";
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      if (file.size / 1024 / 1024 > 100) {
        this.$toasted.error("画像1枚につき100MBまで");
        resetUploader();
      } else if (
        file.type != "image/jpg" &&
        file.type != "image/png" &&
        file.type != "image/jpeg"
      ) {
        this.$toasted.error("画像はjpeg、jpg、png形式である必要があります。");
        resetUploader();
      } else {
        var formData = new FormData();
        formData.append("image", file);
        formData.append("id", this.shop_id);

        const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
        const method = "POST";
        await Api.adminRequestServer({
          method,
          url,
          data: formData,
        })
          .then((response) => {
            const url = response.data.data; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    handleImageRemove(file) {
      const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
      const method = "POST";
      Api.adminRequestServer({
        method,
        url,
        data: file,
      });
    },
    returnList() {
      this.$router.go(-1);
    },
    async SettingGeneral() {
      this.isLoading = true;
      const shop_id = this.shop_id;
      const listMailMega = [];
      if (this.inquiry_URL && !this.inquiry_URL.match(Constants.REGEX_URL)) {
        this.isLoading = false;
        return this.$toasted.error("お問い合わせURLの形式が正しくありません");
      }
      if (
        this.canceling_membership_url &&
        !this.canceling_membership_url.match(Constants.REGEX_URL)
      ) {
        this.isLoading = false;
        return this.$toasted.error(
          "会員登録解除後リダイレクトURLの形式が正しくありません"
        );
      }
      if (this.email_newsletter !== null) {
        this.email_newsletter.map((item) => {
          listMailMega.push(item.value);
        });
      }
      var formData = new FormData();
      formData.append("id", this.id);
      formData.append("shop_id", shop_id);
      formData.append("logo", this.imageLogo ? this.imageLogo : "");
      formData.append(
        "background",
        this.imageBackgroundLogin ? this.imageBackgroundLogin : ""
      );
      formData.append("image_404", this.image404 ? this.image404 : "");
      formData.append(
        "privacy_policy",
        this.privacy_policy ? this.privacy_policy : ""
      );
      formData.append(
        "law_commercial_url",
        this.specified_URL ? this.specified_URL.trim() : ""
      );
      formData.append(
        "inquiry_URL",
        this.inquiry_URL ? this.inquiry_URL.trim() : ""
      );
      formData.append(
        "qa_url",
        this.inquiry_URL ? this.inquiry_URL.trim() : ""
      );
      if (listMailMega && listMailMega.length > 0) {
        listMailMega.forEach((item, index) => {
          formData.append("form_html[" + index + "]", item);
        });
      }
      formData.append(
        "redirect_cancel_member_url",
        this.redirect_URL ? this.redirect_URL.trim() : ""
      );
      formData.append(
        "url_delete_account",
        this.canceling_membership_url
          ? this.canceling_membership_url.trim()
          : ""
      );
      formData.append(
        "note_term",
        this.managementofsalessite ? this.managementofsalessite : ""
      );
      formData.append("px", this.line_spacing ? this.line_spacing : 0);
      formData.append("delete_allow_account", this.enable_membership);
      formData.append(
        "setting_theme",
        this.theme_setting ? this.theme_setting : ""
      );
      formData.append("css", this.additional_css ? this.additional_css : "");
      formData.append("is_delete_logo", this.is_delete_logo);
      formData.append("is_delete_login", this.is_delete_login);
      formData.append("is_delete_404", this.is_delete_404);
      formData.append("shop_title", this.shopTitle ? this.shopTitle : "");
      formData.append("author_default", this.author_default);
      formData.append("is_maintenance", this.isMaintenance);
      formData.append("is_contextmenu", this.isContextMenu);
      formData.append(
        "url_maintenance",
        this.urlMaintenance ? this.urlMaintenance : ""
      );
      // formData.append(
      //   "distributor_name",
      //   this.distributor_name ? this.distributor_name : ""
      // );
      const dataReturn = await this.GerenalSetting(formData);
      if (dataReturn.success === true) {
        localStorage.setItem(Constants.TITLE_SHOP, this.shopTitle);
        if (this.$route.params.shopId) {
          this.$router.push({
            name: "DashboardAdminSetting",
            params: { shopId: this.shop_id },
          });
        } else {
          this.$router.push({
            name: "DashboardAdminSetting domain",
          });
        }
      } else {
        this.isLoading = false;
      }
    },
    scrollMailMega() {
      if (
        Math.round(
          document.getElementsByClassName("multiselect__content-wrapper")[0]
            .scrollTop +
            document.getElementsByClassName("multiselect__content-wrapper")[0]
              .clientHeight
        ) ===
        document.getElementsByClassName("multiselect__content-wrapper")[0]
          .scrollHeight
      ) {
        this.loadingContent = true;
        const requestContent = {
          data: {
            shop_id: this.shop_id,
          },
          page: this.page,
        };
        this.page += 1;
        this.getlistMailMega(requestContent);
      }
    },
    fomatObject(array, object) {
      if (array && array.length > 0) {
        let ArrrayObject = [];
        Object.keys(object)
          .filter((key) => array.includes(parseInt(key)))
          .reduce((obj, key) => {
            ArrrayObject.push(object[key]);
          }, {});
        return ArrrayObject;
      }
    },
  },
};
</script>
<style scoped>
.fontStyle {
  font-size: 14px;
}
.create-form {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 0px !important;
}
.custom-text cp-label {
  background-color: rgb(213, 213, 213);
  height: 40px;
  align-items: center;
  display: flex;
}
.textMust {
  color: #fff;
  background: red;
  padding: 2px 0px;
  border-radius: 5px;
  top: 0px;
  margin-left: 15px;
  font-size: 11px;
}
.row-input input {
  margin: 0px !important;
}
</style>
